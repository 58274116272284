import styled from "styled-components";

export const Container = styled.div` 
  display:flex;
  flex-wrap:wrap; 
    grid-gap: 0.8rem;
  justify-content:center;
  padding:1rem;
`;

