import * as C from "./App.style";
import * as Style from "./AboutMe.style";
import { SITE_DATA } from "./SiteData";
import { useEffect, useState } from "react";

export default function AboutMe() {
  const [showMoreAbout, setShowMoreAbout] = useState(false);
  const [readMoreText, setReadMoreText] = useState("Read more");

  const myAge = (year, month, day) => {
    const birthDate = new Date(year, (month -1), day);
    const today = new Date();

    const yearsDiff = today.getYear() - birthDate.getYear();
    const monthsDiff = today.getMonth() - birthDate.getMonth();

    if (monthsDiff < 0 || (monthsDiff === 0 && today.getDate() < birthDate.getDate())) {
      return (yearsDiff - 1);
    }

    return yearsDiff;
  };

  useEffect(() => {
    setReadMoreText(!showMoreAbout ? "Read more" : "Show less");
  }, [showMoreAbout]);

  return (
    <>
      <C.Title id="about">About me</C.Title>
      <C.ContainerAboutMe>
        <Style.AvatarContent>
          <C.AvatarRounded style={{ margin: "0 auto", marginTop: "1rem" }} />

          <Style.CustomP
            style={{
              // backgroundColor: "green",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            <b>Bruno Alvarez</b> <br />
            {myAge(1984,9,26)} years old <br />
            <i>Full Stack Developer</i>
            <br />
            <br />
            <a rel="noreferrer" target="_blank" href={SITE_DATA.links.linkedin}>
              Linkedin
            </a>
          </Style.CustomP>
        </Style.AvatarContent>
        <C.DivFlex>
          <C.Paragraph>
            Hi! My name is Bruno,
            <br />I am a software engineer based in São Paulo - Brazil with 14
            years of experience in the software industry.
          </C.Paragraph>
          {showMoreAbout && (
            <C.Paragraph>
              I started to work with the extinct Windows Mobile and Palm Os, at
              that time I used the languages C#, C++, VB6, VB net, also working
              on websites created in ASP NET, Windows Forms and the maintenance
              of different databases such as: SQL Server, My Sql, Oracle, etc.
              <br />
              <br />
              I spent a few years working as a Full Stack Developer in Web
              development, learning different technologies at the time, but
              mostly coding in ASP.NET (C#, VB Net) and VB6 until 2017 where I
              started working not only with .NET for front-end and back-end
              development but also in the mobile area, working with Xamarin
              almost 80% of the time. I started to like mobile development and
              learn Android (JAVA) and at the same time all the front-end
              libraries related to Node.js like React and Angular.
              <br />
              <br />I come from a time when there was no separation from
              front-end and back-end, so I have always acted in practically all
              stages of a system's development. It is easy for me to learn new
              technologies and I have no problem adapting to a project
              regardless of the stack. Check out my work experiences for more
              details, best regards!
            </C.Paragraph>
          )}

          <br />
          {/* <img src="/vegan_1.png" width={120} height={100} /> */}
          <Style.ReadMoreContent>
            <C.Button onClick={() => setShowMoreAbout(!showMoreAbout)}>
              {readMoreText}
            </C.Button>
          </Style.ReadMoreContent>
        </C.DivFlex>
      </C.ContainerAboutMe>
    </>
  );
}
