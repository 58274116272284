import * as C from "./App.style";
import styled from "styled-components";
// import { getListOfFlagImages } from "./SiteData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeSquare, faFile } from "@fortawesome/free-solid-svg-icons";

import { SITE_DATA } from "./SiteData";
import { Button as ButtonApp } from "./App.style";

import { logEvent } from "firebase/analytics";

// const FlagImage = styled.img`
//   width: 22px;
//   height: 14px;
// `;

const LinkedinLogo = styled.img`
  width: 30px;
  height: 30px;
`;

const ContactGridLinks = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 0.8rem;
  justify-content: center;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CustomButton = styled(ButtonApp)`
  min-width: 150px;
`;

export default function Contact({ analytics }) {
  // const flagPt = getListOfFlagImages().filter((flag) => flag.label === "pt")[0];
  // const flagEn = getListOfFlagImages().filter((flag) => flag.label === "en")[0];

  const registerAnalytics = (keyAnalytics) => {
    logEvent(analytics, keyAnalytics);
  };

  return (
    <div style={{ textAlign: "center", marginBottom: "1.5rem" }}>
      <C.Title id="contact">Contact</C.Title>
      {/* <h1>Entre em contato comigo!</h1> */}
      <h2>aesterionsystems@gmail.com</h2>
      <br />

      <ContactGridLinks>
        <CustomButton
          medium
          onClick={() => {
            registerAnalytics("click_linkedin");
            window.open(SITE_DATA.links.linkedin, "_blank");
          }}
        >
          Linkedin
          <br />
          <LinkedinLogo src={process.env.PUBLIC_URL + "/linkedin.png"} />
          <i class="fab fa-linkedin"></i>
        </CustomButton>
        <CustomButton
          medium
          style={{ minWidth: "150px" }}
          onClick={() => {
            registerAnalytics("click_email");
            window.location.href = "mailto:aesterionsystems@gmail.com";
          }}
        >
          E-mail
          <br />
          <FontAwesomeIcon
            style={{ fontSize: "2rem", color: "var(--primary-color)" }}
            icon={faEnvelopeSquare}
          ></FontAwesomeIcon>
        </CustomButton>
        <CustomButton
          medium
          style={{ minWidth: "150px" }}
          onClick={() => {
            registerAnalytics("click_resume");
            window.open(
              "https://beautiful-mandevilla-1b3.notion.site/Resume-bd51b6b8cd4b43328d7d474c0606e2a8",
              "_blank"
            );
          }}
        >
          RESUME
          <br />
          <FontAwesomeIcon
            style={{ fontSize: "2rem", color: "var(--primary-color)" }}
            icon={faFile}
            onClick={() => {
              registerAnalytics("click_resume");
              window.open(
                "https://beautiful-mandevilla-1b3.notion.site/Resume-bd51b6b8cd4b43328d7d474c0606e2a8",
                "_blank"
              );
            }}
          ></FontAwesomeIcon>
        </CustomButton>
      </ContactGridLinks>
      {/* <h1>Faça o download do meu currículo:</h1>
      <ContactGridLinks>
        <CustomButton
          medium
          onClick={() =>
            (window.location.href =
              process.env.PUBLIC_URL + "/pt_cv_bruno_alvarez_2021.pdf")
          }
        >
          Cv - pt <FlagImage src={flagPt.img} />{" "}
        </CustomButton>
        <CustomButton
          medium
          onClick={() =>
            (window.location.href =
              process.env.PUBLIC_URL + "/en_cv_bruno_alvarez_2021.pdf")
          }
        >
          Cv - en <FlagImage src={flagEn.img} />{" "}
        </CustomButton>
      </ContactGridLinks> */}
    </div>
  );
}
