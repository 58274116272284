import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as C from "./App.style";
import { SITE_DATA } from "./SiteData";
//import { faCode, faServer } from "@fortawesome/free-solid-svg-icons";
import * as Style from "./ProfessionalSkills.style";
import Card from "./components/Card";

export default function ProfessionalSkills() {
  const skillList = SITE_DATA.skills;
  const highlights = SITE_DATA.skills.highlights;

  const newItemList = (props) => {
    const { title, values, icon } = props;
    return (
      <Card>
        <FontAwesomeIcon
          style={{ fontSize: "3rem", color: "var(--primary-color)" }}
          icon={icon}
        ></FontAwesomeIcon>
        <h1>{title}</h1>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {values.map((item) => {
            return (
              <C.LabelCommon
                className={
                  highlights.includes(item) ? "app-glow-highlight" : ""
                }
              >
                {item}
              </C.LabelCommon>
            );
          })}
        </div>
      </Card>
    );
  };

  return (
    <>
      <C.Title id="skills">Professional Skills</C.Title>
      <Style.Container>
        {skillList.data.map((item) => {
          return newItemList(item);
        })}
      </Style.Container>
    </>
  );
}
