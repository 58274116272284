import styled from "styled-components";

export const ProjectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.8rem;
  justify-content: center;
  padding: 1rem;
`;

export const ProjectItemContainer = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
`;

export const ItemContainer = styled.div`
  text-align: center;
  border: 1px solid black;
  background-color: black;
  max-height: 270px;
`;

export const LabelMadeItWith = styled.div`
  color: var(--primary-color);
  font-size: 1.5rem;
  background-color: black;
  font-weight: bold;
  margin-top: 1rem;
`;

export const LabelTech = styled.div`
  color: var(--primary-color);
  font-size: 1rem;
  /* background-color: var(--primary-color); */
  font-weight: bold;
  border: 1px solid var(--primary-color);
  margin: 10px 5px;
  padding: 5px 15px;
`;
