import styled from "styled-components";

export const AvatarContent = styled.div`
  /* @media (max-width: 800px) {
    display: flex;
  } */
`;

export const CustomP = styled.p`
  text-align: center;
  font-weight: bold;

  @media (max-width: 800px) {
    /* width: 50%; */
    padding-top: 20px;
  }
`;

export const ReadMoreContent = styled.div`
  
  @media (max-width:800px){
    text-align: center;
  }
`;
