import styled from "styled-components";

const PADDING_DEFAULT = "8px";
const MARGIN_DEFAULT = "8px";

export const Container = styled.div`
  margin: ${MARGIN_DEFAULT};
  border: 2px solid var(--primary-color);
  font-family: "Nunito", sans-serif;
  width: 100%;
  max-width: 1200px;
`;

export const MainFlexBox = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const ContainerAboutMe = styled.div`
  display: grid;
  margin: ${MARGIN_DEFAULT};
  @media (min-width: 800px) {
    grid-template-columns: 0.4fr 1.8fr;
  }
`;

export const HeaderContainer = styled.div`
  background: black;
  padding: ${PADDING_DEFAULT};
  display: "flex";
`;

export const Title = styled.h1`
  text-align: center;
  /* text-shadow: 2px 2px white; */
  font-size: 3rem;
  font-weight: 1000;
  background-color: var(--primary-color);
  color: black;
  margin: 0px;
`;

export const SideBarSocialContainer = styled.div`
  background-color: var(--primary-color);
  margin-top: ${MARGIN_DEFAULT};
  margin-bottom: ${MARGIN_DEFAULT};
`;

export const Paragraph = styled.p`
  font-size: 1.5rem;
`;

export const DivFlex = styled.p`
  display: flex;
  flex-direction: column;
`;

export const AvatarRounded = styled.div`
  background: black;
  border: 2px solid var(--primary-color);
  width: 125px;
  height: 125px;
  margin: ${MARGIN_DEFAULT};
  border-radius: 50%;
  background-image: url(${process.env.PUBLIC_URL + "/avatar_2022.jpeg"});
  background-size: 130px;
  background-repeat: no-repeat;
`;

export const Button = styled.button`
  padding: 9px 9px;
  border: 1px solid var(--primary-color);
  background-color: black;
  color: var(--primary-color);
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1rem;
  max-width: 120px;
  font-size: ${(props) => (props.medium ? "1.5rem" : "")};
  :hover {
    color: white;
    border: 1px solid white;
  }
`;

export const LinkButton = styled.a`
  padding: 9px 9px;
  background-color: black;
  color: var(--primary-color);
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.9rem;
  :hover {
    color: white;
    border: 1px solid white;
  }
`;

export const ArrowBackToTop = styled.div`
  /* background-color: var(--primary-color); */
  background-color: black;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem;
  text-align: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
`;

export const CvDownloadButton = styled.div`
  background-color: var(--primary-color);
  border: 1px solid black;
  color: black;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0rem 1rem 1rem 1rem;
  text-align: center;
  align-items: center;
  padding: 0.6rem;
  /* width: 2.5rem;
  height: 2.5rem; */
`;

export const LabelCommon = styled.div`
  color: white;
  font-size: 1rem;
  background-color: black;
  font-weight: bold;
  border: 1px solid white;
  margin: 10px 5px;
  padding: 5px 15px;
`;

export const LabelsContainer = styled.div`
  text-align: center;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
