import * as C from "./App.style";
import Projects from "./Projects";
import AboutMe from "./AboutMe";
import Clients from "./Clients";
import Header from "./Header";
import ProfessionalSkills from "./ProfessionalSkills";
// import TheFly from "./components/TheFly";
// import Modal from "./components/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Contact from "./Contact";
import Footer from "./Footer";
// import { useState } from "react";
// import { setCurrentLanguage } from "./SiteData";
//import Counter from "./Task2";
import CvFloatingButton from "./components/CvFloatingButton";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

function App() {
  //Obtem idioma da url
  //const [selectedLang, setSelectedLang] = useState(window.location.pathname);
  //console.log("PARAM_", selectedLang);

  //setCurrentLanguage(selectedLang);

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyCoziYnZ26OgMJbPAMKRM29Et-RFF2mJ08",
    authDomain: "bruno-portfolio-42ad3.firebaseapp.com",
    projectId: "bruno-portfolio-42ad3",
    storageBucket: "bruno-portfolio-42ad3.appspot.com",
    messagingSenderId: "286591353739",
    appId: "1:286591353739:web:34a08c2d905f74e2a6658e",
    measurementId: "G-9LKYTYER31",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  logEvent(analytics, "new_visit");

  return (
    <>
      {/* <TheFly>|m|</TheFly> */}
      <a href="#top" style={{ cursor: "pointer" }}>
        <C.ArrowBackToTop>
          <div style={{ padding: "6px", fontSize: "1.5rem" }}>
            <FontAwesomeIcon icon={faArrowUp}></FontAwesomeIcon>
          </div>
        </C.ArrowBackToTop>
      </a>

      <CvFloatingButton />

      {/* <Modal title="Download CV" show>
        Escolha o idioma : <br />
        <C.Button>
          Download
        </C.Button>

      </Modal> */}

      <main id="top">
        {/* <Task2 /> */}

        <C.MainFlexBox>
          <C.Container>
            <Header />
            <AboutMe />
            <Projects />
            <ProfessionalSkills />
            <Clients />
            <Contact analytics={analytics} />
            <Footer />
          </C.Container>
        </C.MainFlexBox>
      </main>
    </>
  );
}

export default App;
