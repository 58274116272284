import styled from "styled-components";

const Container = styled.div`
  background-color: var(--primary-color);
  text-align: center;
  padding: 1.5rem;
  color: black;
`;

export default function Footer() {
  return <Container>Bruno's Portfolio 2022 - All rights reserved</Container>;
}
