// import { useState } from "react";
import * as C from "./App.style";
import * as Style from "./Header.style";
import {
  //getPublicFolder,
  getListOfFlagImages,
  // setCurrentLanguage,
} from "./SiteData";

export default function Header() {
  //const [currentLanguage, setCurrentSelectLanguage] = useState("en");
  //const [showLanguagesDDL, setShowLanguagesDDL] = useState(false);
  const list = getListOfFlagImages();

  const customLanguageSelectList = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          float: "right",
        }}
      >
        {list.map((language) => {
          return (
            <Style.ContainerCustomDropDown
              onClick={() => handleSelectLanguage(language.label)}
            >
              {flagOption(language.label, language.img, false)}
            </Style.ContainerCustomDropDown>
          );
        })}
      </div>
    );
  };

  const handleSelectLanguage = (language) => {
    // if (currentLanguage !== language) {
    //   setCurrentLanguage(language);
    //   setCurrentSelectLanguage(language);
    // }
    // setShowLanguagesDDL(false);
  };

  const flagOption = (label, img, showArrow) => {
    return (
      <>
        <div>
          <Style.FlagContainer src={img} />
        </div>
        <Style.LanguageLabel>
          {label}
          {"  "}
        </Style.LanguageLabel>

        <Style.ArrowDown show={showArrow} />
      </>
    );
  };

  // const selectedLanguage = () => {
  //   var selected = getListOfFlagImages().filter((_) => {
  //     return _.label === currentLanguage;
  //   })[0];

  //   return flagOption(selected.label, selected.img, true);
  // };

  return (
    <>
      <C.HeaderContainer>
        <Style.HeaderContainer>
          <div style={{ flex: "3", display: "flex" }}>
            <Style.MenuLinksContainer>
              <Style.MenuItemLink href="#about">ABOUT </Style.MenuItemLink>
              <Style.MenuItemLink href="#projects">PROJECTS</Style.MenuItemLink>
              <Style.MenuItemLink href="#skills">SKILLS </Style.MenuItemLink>
              <Style.MenuItemLink href="#clients">CLIENTS </Style.MenuItemLink>
              <Style.MenuItemLink href="#contact">CONTACT</Style.MenuItemLink>
            </Style.MenuLinksContainer>
          </div>
          <div
            style={{
              flex: "1",
              position: "relative",
            }}
          >
            {/* <Style.ContainerCustomDropDown
              onClick={() => setShowLanguagesDDL(!showLanguagesDDL)}
            >
              {selectedLanguage()}
            </Style.ContainerCustomDropDown> */}
            <div
              style={{
                opacity: 0,
                position: "absolute",
                right: 0,
                top: "35px",
                transition: "opacity 0.5s",
              }}
            >
              {customLanguageSelectList()}
            </div>
          </div>
        </Style.HeaderContainer>
      </C.HeaderContainer>
    </>
  );
}
