import styled from "styled-components";

export const CardContainer = styled.div`
  border: 1px solid var(--primary-color);
  padding:1rem;
  background-color: black;
  display:flex;
  flex-direction:column;
  text-align:center;
  max-width:${props => props.size};
  min-width:300px;
`;

