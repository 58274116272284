import { useState } from "react";
import * as Styled from "./Projects.style";
import * as C from "./App.style";
import { SITE_DATA } from "./SiteData";
import Card from "./components/Card";

export default function Projects() {
  const [projects] = useState(SITE_DATA.projects);

  return (
    <>
      <C.Title id="projects">Projects</C.Title>
      <Styled.ProjectContainer>
        {projects.map((project) => {
          return (
            <Card key={project.key} size="500px">
              <div style={{ backgroundColor: "black", textAlign: "center" }}>
                <img
                  alt=""
                  src={project.image}
                  width="100%"
                  height="auto"
                  style={{ borderRadius: "0.5rem" }}
                />
                <br />

                {/* <Styled.LabelMadeItWith>
                {project.language}
                <br />
              </Styled.LabelMadeItWith> */}
                <Styled.LabelMadeItWith>
                  {project.label}
                  <hr />
                </Styled.LabelMadeItWith>
                <div>
                  {project.description.map((desc) => {
                    return <p style={{ fontSize: "1.2rem" }}>{desc}</p>;
                  })}

                  <a href={project.link} target="_blank" rel="noreferrer">
                    WEBSITE
                  </a>
                  <hr />
                  <C.LabelsContainer>
                    {project.techs.map((tech) => {
                      return <Styled.LabelTech> {tech}</Styled.LabelTech>;
                    })}
                  </C.LabelsContainer>
                </div>
              </div>
            </Card>
          );
        })}
      </Styled.ProjectContainer>
    </>
  );
}
