import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 9px;
  grid-template-columns: 1fr 1fr;
`;

export const ContainerCustomDropDown = styled.div`
  display: flex;
  background-color: black;
  font-size: 1.2rem;
  float: right;
  min-width: 85px;
  cursor: pointer;
  border: var(--primary-color) 1px solid;
`;

export const MenuLinksContainer = styled.div`
  padding: 3px;
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const ArrowDown = styled.div`
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 16px solid ${(props) => (props.show ? "white" : "black")};
  width: 0;
  height: 0;
  margin: 6px 6px;
`;

export const FlagContainer = styled.img`
  margin: 6px 6px;
  width: 24px;
  height: 16px;
`;

export const LanguageLabel = styled.div`
  margin: 0px 6px;
  font-size: 1.5rem;
`;

export const MenuItemLink = styled.a`
  margin: 6px 9px;
  font-size: 2rem;
  font-weight: bold;
`;
