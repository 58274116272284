import * as C from "./App.style";
import * as Style from "./Clients.style";
import { SITE_DATA } from "./SiteData";

export default function Clients({ scr }) {
  return (
    <>
      <C.Title id="clients">Clients</C.Title>
      <Style.GridContainer>
        {SITE_DATA.clients.map((client) => {
          return (
            <Style.ItemClient>
              <img
                title={client.name}
                alt="project"
                src={client.img}
                style={{ maxWidth: "90px", maxHeight: "90px" }}
              />

              {/* {client.name} */}
            </Style.ItemClient>
          );
        })}
      </Style.GridContainer>
    </>
  );
}
