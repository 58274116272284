import styled from "styled-components";
export const GridContainer = styled.div`
  background-color: white;
  display: grid;
  align-items: center;
  text-align: center;
  grid-gap: 12px;
  padding: 21px 0px;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const ItemClient = styled.div`
  /* background-color: blue; */
`;
