import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as C from "../App.style";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const CvFloatingButton = () => {
  return (
    <a
      href="https://beautiful-mandevilla-1b3.notion.site/Resume-bd51b6b8cd4b43328d7d474c0606e2a8"
      rel="noreferrer"
      target="_blank"
    >
      <C.CvDownloadButton>
        VIEW RESUME
        <FontAwesomeIcon
          style={{ marginLeft: "1rem" }}
          icon={faDownload}
        ></FontAwesomeIcon>
      </C.CvDownloadButton>
    </a>
  );
};

export default CvFloatingButton;
