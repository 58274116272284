// import { useState } from "react";

import {
  faToolbox,
  faLanguage,
  faCode,
  faServer,
  faDatabase,
  faCloud,
} from "@fortawesome/free-solid-svg-icons";

const clientsImageFolder = process.env.PUBLIC_URL + "/clients";
const getClientImage = (pathFileName) => {
  return clientsImageFolder + pathFileName;
};

const getProjectImage = (pathFileName) => {
  return process.env.PUBLIC_URL + "/projects" + pathFileName;
};

export const getPublicFolder = () => {
  return process.env.PUBLIC_URL;
};

export const getListOfFlagImages = () => {
  return [
    { label: "pt", img: getPublicFolder() + "/brasil-flag.png" },
    { label: "en", img: getPublicFolder() + "/english-flag.png" },
    { label: "es", img: getPublicFolder() + "/spain-flag.png" },
  ];
};

export const SITE_DATA = {
  skills: {
    highlights: [
      "Spanish",
      "C#",
      "Java Script",
      "English",
      "Java",
      "Node",
      "React",
      "Angular",
      "NET Framework",
      "NET Core",
      "Xamarin",
      "Android",
      "JQuery",
      "CSS",
      "HTML",
      "Firebase",
      "SQL Server",
      "MySQL",
      "Firestore",
      "Photoshop",
      "Cakewalk Sonar",
      "Rest API",
      "Express Js",
    ],
    data: [
      {
        title: "back-end",
        icon: faServer,
        values: [
          "C",
          "C++",
          "C#",
          "Python",
          "Java",
          "Node",
          "VB6",
          "VB.NET",
          "PHP",
          "ASP 3",
          "NET Framework",
          "Express Js",
          "Rest API",
          "NET Core",
        ],
      },
      {
        title: "front-end",
        icon: faCode,
        values: [
          "React",
          "Angular",
          "Vue",
          "Android",
          "IOS",
          "Xamarin",
          "JQuery",
          "Java Script",
          "Wordpress",
          "Swift",
          "CSS",
          "HTML",
          "Redux",
          "Next Js",
          "TypeScript",
        ],
      },
      {
        title: "databases",
        icon: faDatabase,
        values: [
          "SQL Server",
          "MySQL",
          "Oracle",
          "Sqlite",
          "PostGre",
          "Firestore",
        ],
      },
      {
        title: "cloud",
        icon: faCloud,
        values: ["Firebase", "Azure", "AWS"],
      },
      {
        title: "languages",
        icon: faLanguage,
        values: ["English", "Spanish"],
      },
      {
        title: "others",
        icon: faToolbox,
        values: ["Photoshop", "Unreal Engine 4", "Cakewalk Sonar"],
      },
    ],
  },

  links: {
    linkedin: "https://www.linkedin.com/in/bruno-alvarez-95b61751/",
  },
  menu: [
    {
      en: {
        menuTitle: "About",
        menuTitleSection: "About Me",
      },
    },
  ],
  contents: {
    EN: {
      aboutMe: "About me",
      madeItIn: "Made it in",
    },
    PT: {
      aboutMe: "Sobre mim",
      madeItIn: "Feito em",
    },
    ES: {},
  },
  clients: [
    {
      name: "Akzo Nobel",
      img: getClientImage("/akzonobel.webp"),
    },
    {
      name: "Newcore",
      img: getClientImage("/newcore.svg"),
    },
    { name: "Lopes", img: getClientImage("/lopes.svg") },
    {
      name: "Serasa Experian",
      img: getClientImage("/serasa.png"),
    },
    { name: "Cielo", img: getClientImage("/cielo.svg") },
    { name: "Hp", img: getClientImage("/hp.png") },
    { name: "Rassini(RNA)", img: getClientImage("/rna.png") },
    { name: "Bematech", img: getClientImage("/bematech.png") },
    {
      name: "Liberty Seguros",
      img: getClientImage("/liberty.png"),
    },
    {
      name: "Bradesco Cartões",
      img: getClientImage("/bradesco.png"),
    },
    {
      name: "Connectcar",
      img: getClientImage("/conectcar.png"),
    },
    {
      name: "Sofisa Direto",
      img: getClientImage("/sofisa.png"),
    },

    {
      name: "Quantzed",
      img: getClientImage("/quantzed.png"),
    },
    {
      name: "Ambipar",
      img: getClientImage("/ambipar.png"),
    },
    {
      name: "Portobello",
      img: getClientImage("/portobello.png"),
    },
    {
      name: "Basta Trânsito",
      img: getClientImage("/basta.png"),
    },
  ],
  projects: [
    {
      key: "project2",
      label: "Newcore - App",
      image: getProjectImage("/newcoreapp.png"),
      language: "Android - Java",
      description: [
        "A tool that offers several features to help brokers.",
        "With the NEWCORE APP, the broker can better understand the buyer, optimizing the service and increasing its results.",
      ],
      techs: ["Android", "Java", "Sqlite", "Firebase"],
      link: "https://play.google.com/store/apps/details?id=br.com.newcore",
    },

    {
      key: "project5",
      label: "Sofisa Direto",
      image: getProjectImage("/sofisa.png"),
      language: "Xamarin C# - Android / IOS ",
      description: [
        "100% digital account with bank security and broker profitability.",
      ],
      techs: ["Xamarin Forms", "Android", "IOS"],
      link: "https://play.google.com/store/apps/details?id=goova.sofisa.client.v2",
    },

    {
      key: "project1",
      label: "Lopesnet Prontos",
      image: getProjectImage("/lopesnet.prontos.png"),
      language: "Asp Net Core 4.5",
      description: [
        "Development and maintenance of the integrated real estate network LopesNet Prontos.",
        "https://prontos.lopesnet.com.br",
      ],
      techs: [
        "C#",
        ".NET Core",
        "ASP.NET 4.5 MVC",
        "Rest API",
        "Bootstrap",
        "SQL Server",
        "Entity Framework",
        "JsViews",
        "SOLR",
        "Unit Test",
        "Selenium",
        "Nagios",
      ],
      link: "https://prontos.lopesnet.com.br/",
    },
    {
      key: "project3",
      label: "Newcore - Site",
      image: getProjectImage("/newcore.site.png"),
      language: "React Js / Next Js",
      description: [
        "Real estate website development using React Next.js, Material-UI, Redux",
        "API development using Node and MySql",
        "Firebase stack maintenance",
      ],
      techs: [
        "React",
        "Next Js",
        "Node",
        "Nest Js",
        "Express Js",
        "Redux",
        "Styled Components",
        "Material-UI",
        "MySQL",
        "Firebase",
      ],
      link: "https://comprar.newcore.com.br/",
    },
    {
      key: "project4",
      label: "Ambipar",
      image: getProjectImage("/ambipar_siga.png"),
      language: "C# / NET Core",
      description: [
        "Ambipar acts in several sectors to provide complete services and products related to environmental management.",
        "Undergoing a worldwide expansion, Ambipar respects compliance rules, as well as socio-environmental responsibility rules, cherishing the ethics and the prompt service to the quests of its customers.",
      ],
      techs: ["NET Core", "Sql Server", "ASP NET MVC", "React Native"],
      link: "https://ambipar.com/en/",
    },
    {
      key: "project5",
      label: "Portobello - Concierge",
      image: getProjectImage("/portobello_concierge.png"),
      language: "React",
      description: [
        "Based in Tijucas, Santa Catarina, Portobello is now the largest ceramic tile company in Brazil.",
      ],
      techs: ["React Js", "Firestore (Firebase)", "Type Script"],
      link: "https://concierge.portobello.com.br/",
    },
    {
      key: "project6",
      label: "Quantzed",
      image: getProjectImage("/quantzed.png"),
      language: "Angular",
      description: [
        "Platform created in Angular for access to exclusive quantitative strategies and intelligent information to increase the probability of user success within the financial market.",
      ],
      techs: ["Angular"],
      link: "https://login.quantzed.com.br/#/",
    },
  ],
};

let currentLanguage = "en";

export const setCurrentLanguage = (language) => {
  if (language.includes("/")) language = language.replace("/", "");
  getListOfFlagImages().forEach((lang) => {
    if (language === lang.label) {
      currentLanguage = lang;
    }
  });
  console.log("LANG_PARAM", language);
  console.log("LANG_", currentLanguage);
};

export const getDataByLanguage = (language) => {
  return SITE_DATA.contents[language];
};
